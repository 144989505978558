.main {
  display: flex;
  flex-direction: column;
}

.menu {
  display: flex;
  justify-content: space-between;
  overflow-x: hidden; /* Disable horizontal scroll */
  background:  #ffb3c7;
  font-size: 1.2rem;
}

.menu-list {
  display: flex;
}

.menu-list-item {
  color: white;
  padding: 10px;
  list-style-type: none;
  text-decoration: none;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.menu-list-link {
  color: white;
  text-decoration: none
}

.menu-list-link:hover {
  color: black;
}

.App {
    display: flex;
  }
  
  .App-form {
    display: flex;
    flex-direction: column;
    border: 1px solid #d8d8d8;
    border-radius: 3px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 11%);
    padding: 30px;
    margin: 30px;
    }

  .App-header {
    margin: auto;
    /* background-color: #ffb3c7; */
    padding: 10px;
    display: flex;
    flex-direction: column;
    font-size: 30px;
    color: #282c34; 
    min-width: 1000px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  }

  .App-label-wrapper {
    display: flex;
    flex-direction: row;
  }

  .App-label {
    display: inline-block;
    width: 200px;
    border-radius: 5px;
    border: 3px transparent solid;
    color: #282c34;
    padding: 10px 15px;
    outline: none;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 20px;
    font-weight: 500;
    margin: 10px 0;
  }

  .App-input {
    background: rgba(0,0,0,0.1);
    border-radius: 5px;
    border: 3px transparent solid;
    color: #262626;
    padding: 10px 15px;
    outline: none;
    font-size: 15px;
    font-weight: 500;
    margin: 10px 0;
    min-width: 400px;
  }

  .App-button {
    background: #ffb3c7;
    border: 0;
    color: #282c34;
    cursor: pointer;
    padding: 12px 35px;
    border-radius: 5px;
    font-size: 1.5rem;
    font-weight: bold;
    outline: none;
    transition: all ease 0.3s;
    max-width: 200px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  }
  
  .App-link {
    color: #61dafb;
  }

  .App-list-col {

  }

  .App-list-row {

  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  